import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/__01.LAVORO_wip/ArcheoSIT/sito/index.js";
import { useStaticQuery, graphql } from "gatsby";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faShare, faArrowAltCircleRight, faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import * as Container from "../components/container.js";
import * as Section from "../components/splitSections.js";
import Card from "../components/card.js";
import { Button } from "../components/button.js";
import { ReadMore } from "../components/readMore";
import Anchor from "../components/anchor.js";
import ImageGallery from "../components/imageGallery.js";
import Contatti from "../components/contatti.js";
import Partners from "../components/partners.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Container.Fluid id="sezione1" className="py-5 px-3">
      <h3>{`Perché ArcheoSIT`}</h3>
      <hr />
      <Section.One className="">
        <p>{`ArcheoSIT è un progetto `}<strong parentName="p">{`innovativo`}</strong>{` del "Centro Studi di Archeologia Preventiva Siciliana" per `}<strong parentName="p">{`raccogliere`}</strong>{`, `}<strong parentName="p">{`studiare`}</strong>{` e `}<strong parentName="p">{`divulgare`}</strong>{` dati sulla geografia, topografia e archeologia.`}</p>
        <p>{`ArcheoSIT offre `}<strong parentName="p">{`servizi turistici`}</strong>{` e si occupa di `}<strong parentName="p">{`Archeologia Preventiva`}</strong>{` in fase di `}<strong parentName="p">{`pianificazione territoriale`}</strong>{` e `}<strong parentName="p">{`progettazione`}</strong>{` urbanistica; è lo strumento prezioso per la valutazione del `}<strong parentName="p">{`potenziale archeologico`}</strong>{` delle nostre città e dei territori limitrofi.`}</p>
        <p>{`ArcheoSIT usa la logica dei Sistemi Informativi Territoriali digitali (`}<strong parentName="p">{`SIT`}</strong>{`) con l'`}<strong parentName="p">{`obiettivo`}</strong>{` di raccogliere dati bibliografici e geo-spaziali per la creazione di cataloghi topografici di `}<strong parentName="p">{`interesse storico e archeologico`}</strong>{`.`}</p>
        <p>{`Nella vita servono soluzioni concrete in un mondo che cambia in fretta.`}</p>
        <Button solid inline large targetBlank color={"#A9461D"} hrefJSON={"cta_home_1"} title="Clicca per contattarci" mdxType="Button">
Contattaci adesso per una consulenza
        </Button>
      </Section.One>
      <Section.Two className="pt-4 pt-lg-0">
        <iframe title="Youtube Video" width="100%" height="315" src="https://www.youtube-nocookie.com/embed/6jGHOZgUbFM" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </Section.Two>
      <Section.FullWidth>
      </Section.FullWidth>
    </Container.Fluid>
    <Container.Fluid className="py-5 px-3 text-center" background="#d59f59">
      <div className="col text-nowrap">
        <div className="text-bold h4 m-0">200+</div> SITI ARCHEOLOGICI
      </div>
      <div className="w-100 d-lg-none"></div>
      <div className="col text-nowrap">
        <div className="text-bold h4 m-0">3</div> SITI ARCHEOLOGICI UNESCO
      </div>
      <div className="w-100 d-lg-none"></div>
      <div className="col text-nowrap">
        <div className="text-bold h4 m-0">23</div> AREE ARCHEOLOGICHE
      </div>
      <div className="w-100 d-xl-none"></div>
      <div className="col text-nowrap">
        <div className="text-bold h4 m-0">20</div> PARCHI ARCHEOLOGICI
      </div>
      <div className="w-100 d-lg-none"></div>
      <div className="col text-nowrap">
        <div className="text-bold h4 m-0">50+</div> MUSEI ARCHEOLOGICI
      </div>
      <div className="w-100 d-lg-none"></div>
      <div className="col text-nowrap">
        <div className="text-bold h4 m-0">9</div> PROVINCE
      </div>
      <div className="w-100 d-lg-none"></div>
      <div className="col text-nowrap">
        <div className="text-bold h4 m-0">391</div> COMUNI
      </div>
    </Container.Fluid>
    <Container.Fluid id="sezione2" className="py-5 px-3" background="#ffffff">
      <h3>{`Cosa facciamo`}</h3>
      <hr />
      <Section.One className="py-4 py-lg-0">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1237px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.36363636363636%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB67pRRkR//8QAGBABAAMBAAAAAAAAAAAAAAAAAQARMRD/2gAIAQEAAQUCcL45LZ//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwFX/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGxAAAwACAwAAAAAAAAAAAAAAAAERITFBUWH/2gAIAQEAAT8hrsV5RpSsaOPCZ7H/2gAMAwEAAgADAAAAEKjv/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8QQ//EABoQAQADAQEBAAAAAAAAAAAAAAEAETEhYfH/2gAIAQEAAT8QNwlh2OrD0sgIWKNvYyRpCHi59lHBT//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/d880bc6714822402352288d041ff343a/f2908/cosa_facciamo.webp 330w", "/static/d880bc6714822402352288d041ff343a/cc661/cosa_facciamo.webp 660w", "/static/d880bc6714822402352288d041ff343a/3deba/cosa_facciamo.webp 1237w"],
                "sizes": "(max-width: 1237px) 100vw, 1237px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/d880bc6714822402352288d041ff343a/80fc7/cosa_facciamo.jpg 330w", "/static/d880bc6714822402352288d041ff343a/ee745/cosa_facciamo.jpg 660w", "/static/d880bc6714822402352288d041ff343a/f8350/cosa_facciamo.jpg 1237w"],
                "sizes": "(max-width: 1237px) 100vw, 1237px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/d880bc6714822402352288d041ff343a/f8350/cosa_facciamo.jpg",
                "alt": "Cosa facciamo",
                "title": "Cosa facciamo",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
      </Section.One>
      <Section.Two className="">
        <p>{`Il mondo è cambiato. L’`}<strong parentName="p">{`Archeologia Preventiva`}</strong>{` è la nuova `}<strong parentName="p">{`frontiera`}</strong>{` per la `}<strong parentName="p">{`conoscenza`}</strong>{`, la `}<strong parentName="p">{`prevenzione`}</strong>{` e la `}<strong parentName="p">{`conservazione`}</strong>{` del `}<strong parentName="p">{`patrimonio`}</strong>{` culturale.`}</p>
        <p>{`ArcheoSIT investe sulla `}<strong parentName="p">{`conoscenza`}</strong>{`, `}<strong parentName="p">{`valorizzazione`}</strong>{` e `}<strong parentName="p">{`divulgazione`}</strong>{` dei beni archeologici a livello internazionale.`}</p>
        <p>{`ArcheoSIT propone l’antica città di `}<strong parentName="p">{`ENNA`}</strong>{` come `}<strong parentName="p">{`modello SIT`}</strong>{` di riferimento da `}<strong parentName="p">{`replicare in altri contesti siciliani, italiani ed internazionali`}</strong>{`.`}</p>
        <p>{`ArcheoSIT `}<strong parentName="p">{`interagisce`}</strong>{` con le `}<strong parentName="p">{`persone`}</strong>{` per creare `}<strong parentName="p">{`valore insieme a`}</strong>{`: specialisti di archeologia e topografia antica, docenti, studenti delle facoltà di beni culturali, studiosi e ricercatori indipendenti, Imprese, Pubblica Amministrazione, turisti e cittadini curiosi.`}</p>
        <Button solid inline large targetBlank color={"#A9461D"} hrefJSON={"cta_home_2"} title="Clicca per contattarci" mdxType="Button">
Realizza adesso il SIT della tua città!
        </Button>
      </Section.Two>
    </Container.Fluid>
    <Container.Fluid id="sezione3" className="py-5 px-3" background="#2C7784">
      <Card withSections className="my-4" mdxType="Card">
        <Section.One className="px-3">
          <h3>{`Servizi`}</h3>
          <p>{`Il mondo è cambiato. Il `}<strong parentName="p"><em parentName="strong">{`database`}</em></strong>{` di `}<strong parentName="p">{`ArcheoSIT`}</strong>{` è `}<strong parentName="p">{`strumento fondamentale`}</strong>{` in fase progettuale nell’ambito di `}<strong parentName="p">{`interventi pubblici`}</strong>{` e privati che prevedono `}<strong parentName="p">{`scavi`}</strong>{` a profondità diverse da quelle già interessate da infrastrutture esistenti.`}</p>
          <p>{`ArcheoSIT offre `}<strong parentName="p">{`consulenza`}</strong>{` ed `}<strong parentName="p">{`assistenza`}</strong>{` per la Valutazione di Impatto Paesaggistico e `}<strong parentName="p">{`Verifica Archeologica Preventiva`}</strong>{` per evitare la distruzione delle possibili preesistenze archeologiche.`}</p>
          <p>{`ArcheoSIT si avvale di figure professionali abilitate per la Verifica Preventiva dell’Interesse Archeologico (`}<strong parentName="p">{`ex art. 25 del D. Lgs. 50/2016`}</strong>{`) secondo livelli di `}<strong parentName="p">{`indagine progressiva`}</strong>{` (`}<em parentName="p">{`Progressive Inquiry Model`}</em>{`), dalla `}<strong parentName="p">{`ricerca bibliografica`}</strong>{`, all’esecuzione di ricognizioni, `}<strong parentName="p">{`prospezioni geofisiche`}</strong>{`, sondaggi e `}<strong parentName="p">{`scavi archeologici`}</strong>{`.`}</p>
          <p>{`ArcheoSIT offre servizio di `}<strong parentName="p">{`Sorveglianza Archeologica`}</strong>{` in cantiere durante lo svolgimento di lavori per la realizzazione di opere ed infrastrutture.`}</p>
          <Button solid inline large targetBlank color={"#A9461D"} hrefJSON={"cta_home_3"} title="Clicca per contattarci" mdxType="Button">
Richiedi la prima consulenza!
          </Button>
        </Section.One>
        <Section.Two>
          <ImageGallery mdxType="ImageGallery" />
        </Section.Two>
      </Card>
    </Container.Fluid>
    <Container.Fixed id="sezione4" className="py-5 px-3">
      <h3>{`Archivio Storico Angelo Severino `}<br className="d-block d-xl-none" /><span className="h4"><em parentName="h3">{`Umbilicus Siciliae et Trinakie Hennaion`}</em></span></h3>
      <hr />
      <Section.One col="8" className="pe-xl-4">
        <p>{`ArcheoSIT `}<strong parentName="p">{`cura`}</strong>{` la conservazione, catalogazione e consultazione dell’`}<strong parentName="p">{`Archivio Storico Angelo Severino `}<em parentName="strong">{`Umbilicus Siciliae et Trinakie Hennaion`}</em></strong>{`, storico, giornalista e pubblicista ennese scomparso  prematuramente il 22 marzo 2022.`}</p>
        <p>{`Fondato nel 2022 per volontà del figlio Fabrizio Natanaele, l’Archivio `}<strong parentName="p">{`custodisce oltre 5.000 documenti`}</strong>{`, tra cui volumi, enciclopedie, riviste, articoli, saggi, scritti e tesi di laurea di varia natura e argomentazione, raccolti durante gli anni della carriera di Angelo Severino.`}</p>
        <p>{`ArcheoSIT `}<strong parentName="p">{`promuove`}</strong>{` l’incremento del patrimonio archivistico e librario dell’`}<strong parentName="p">{`Archivio Storico Angelo Severino `}<em parentName="strong">{`Umbilicus Siciliae et Trinakie Hennaion`}</em></strong>{` con volumi, riviste, saggi, tesi di laurea e documenti di alto valore culturale.  L’Archivio è gestito secondo la tipologia di collocazione “scaffale chiuso”.`}</p>
      </Section.One>
      <Section.Two col="4" className="py-4 px-5 mt-4 mt-lg-0 pt-lg-0 pb-lg-0 pt-lg-0" style={{
        "background": "#A9461D",
        "borderRadius": "8px",
        "boxShadow": "0 20px 25px -5px rgba(0,0,0,.1), 0 10px 10px -5px rgba(0,0,0,.04)"
      }}>
        <div className="py-3 py-lg-5" style={{
          color: "#ffffff"
        }}>
          <h4>{`Consulta il nostro archivio oppure dona un volume.`}</h4>
          <p>{`Saremo felici di accoglierti nella sede di Enna.`}</p>
          <Button solid large inline targetBlank color={"#2C7784"} hrefJSON={"cta_home_4"} title="Contattaci per accedere all'Archivio" mdxType="Button">
Contattaci adesso
          </Button>
        </div>
      </Section.Two>
    </Container.Fixed>
    <Container.Fluid id="sezione5" className="py-5 px-3" background="#d59f59">
      <h3>{`Forma Urbis Ennae`}</h3>
      <hr />
      <Section.One className="">
        <p><em parentName="p">{`Forma Urbis Ennae`}</em>{` è il primo `}<strong parentName="p">{`Sistema Informativo Territoriale`}</strong>{` (`}<strong parentName="p">{`SIT`}</strong>{`) archeologico di Enna.`}</p>
        <p>{`Che l’antichissima città di Enna non costituisce soltanto il `}<strong parentName="p">{`centro`}</strong>{` della Sicilia ma il `}<strong parentName="p">{`fulcro`}</strong>{` dell’intero sistema del Mediterraneo è un dato documentato almeno dal IX secolo a.C.`}</p>
        <p>{`Il concetto di `}<em parentName="p">{`Umbilicus Siciliae`}</em>{` ciceroniano è un elemento da `}<strong parentName="p">{`attenzionare`}</strong>{` e studiare attraverso un approccio multidisciplinare di tipo materialista, storico e `}<strong parentName="p">{`multiscalare`}</strong>{`.`}</p>
        <p>{`Verso questa direzione muovono le `}<strong parentName="p">{`indagini`}</strong>{` storiche ed archeologiche essenziali al `}<strong parentName="p">{`rilancio`}</strong>{` turistico e culturale della città.`}</p>
      </Section.One>
      <Section.Two className="pt-4 pt-lg-0">
        <p>{`La scoperta del territorio e del suo `}<strong parentName="p">{`potenziale`}</strong>{` storico ed archeologico è fondamentale per `}<strong parentName="p">{`favorire`}</strong>{` le politiche di tutela, valorizzazione e fruizione dei monumenti superstiti per la pianificazione urbana nel `}<strong parentName="p">{`rispetto`}</strong>{` della conservazione dei resti antichi.`}</p>
        <p>{`La `}<em parentName="p">{`Forma Urbis Ennae`}</em>{` è un modello cartografico digitale dello sviluppo dell’insediamento urbano dalla preistoria all’età greca e romana, dall’età bizantina all’età medievale, fino all’età contemporanea.`}</p>
        <p>{`Il SIT archeologico di Enna è la `}<strong parentName="p">{`chiave d'accesso`}</strong>{` ad una `}<strong parentName="p">{`nuova`}</strong>{` stagione di `}<strong parentName="p">{`studi e ricerche`}</strong>{` in campo archeologico e topografico con l’intento di accrescere il `}<strong parentName="p">{`valore`}</strong>{` della città di Enna.`}</p>
      </Section.Two>
    </Container.Fluid>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1320px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.36363636363636%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMBAgQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAZVoVLc54n//xAAcEAACAgIDAAAAAAAAAAAAAAABAwARAhIiMTL/2gAIAQEAAQUCyFyht1G+mABTTz//xAAWEQEBAQAAAAAAAAAAAAAAAAARACH/2gAIAQMBAT8BcZv/xAAWEQEBAQAAAAAAAAAAAAAAAAARACH/2gAIAQIBAT8BNIv/xAAbEAACAgMBAAAAAAAAAAAAAAAAAREyAhAhcv/aAAgBAQAGPwJW4WyllCB+tf/EABoQAQADAQEBAAAAAAAAAAAAAAEAESFRMYH/2gAIAQEAAT8h2FArryXZ4HZZm8SUOJHANBT8iNlbQn//2gAMAwEAAgADAAAAEA8f/8QAFhEAAwAAAAAAAAAAAAAAAAAAEBEh/9oACAEDAQE/EKYP/8QAFhEAAwAAAAAAAAAAAAAAAAAAEBEh/9oACAECAQE/EIQP/8QAHBABAQACAgMAAAAAAAAAAAAAAREAIUFRMXHh/9oACAEBAAE/EIpCgRWJrXTjVOoASo8cb+5e7nNN3BbAlO94DI7QPfEzSC3mZ//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/01438778418b67012020b44db6e7e5e1/f2908/image05.webp 330w", "/static/01438778418b67012020b44db6e7e5e1/cc661/image05.webp 660w", "/static/01438778418b67012020b44db6e7e5e1/d33d4/image05.webp 1320w", "/static/01438778418b67012020b44db6e7e5e1/882b9/image05.webp 1920w"],
            "sizes": "(max-width: 1320px) 100vw, 1320px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/01438778418b67012020b44db6e7e5e1/80fc7/image05.jpg 330w", "/static/01438778418b67012020b44db6e7e5e1/ee745/image05.jpg 660w", "/static/01438778418b67012020b44db6e7e5e1/be3c7/image05.jpg 1320w", "/static/01438778418b67012020b44db6e7e5e1/0f98f/image05.jpg 1920w"],
            "sizes": "(max-width: 1320px) 100vw, 1320px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/01438778418b67012020b44db6e7e5e1/be3c7/image05.jpg",
            "alt": "Timeline",
            "title": "Timeline",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <Container.Fluid id="contatti" className="py-5 px-3">
      <Section.One col="8">
        <h3>{`Contatti`}</h3>
        <Contatti mdxType="Contatti" />
      </Section.One>
      <Section.Two col="4" className="pt-3 pt-lg-0">
        <h3>{`Partners`}</h3>
        <Partners mdxType="Partners" />
      </Section.Two>
    </Container.Fluid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      